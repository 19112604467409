import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFares from "../components/metro-fares"

class G1ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="G1"
          description="View route information and buy your ticket for the G1 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">G1 service</h1>
              <p>Seacroft Bus Station - Garforth Academy</p>
              <p>Garforth Academy - Seacroft Bus Station</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="G1 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1h4Iha4tSj_CnMh7Lw0f8Vr8y8A_oe4I&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Garforth Academy
                </p>
                <p>
                  From Seacroft Avenue, York Road, Scholes Lane, Station Road, Main Street, Leeds Road, Elmwood Lane, Main Street, Long Lane, Barwick Road, Barrowby Lane, Main Street, Lidgett Lane.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="G1 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1bYjpnCaYcNtRWWGKok44zcsIjwMdbk8&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Garforth Academy
                </p>
                <p>
                  From Lidgett Lane, B6137, Main Street, Town End, Barwick Road, Long Lane, Main Street, Elmwood Lane, Leeds Road, Main Street, Station Road, Scholes Lane, A64, A6120, York Road, Seacroft Avenue.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Seacroft Bus Station</td>
                      <td>DEP</td>
                      <td>0730</td>
                    </tr>
                    <tr>
                      <td>Scholes Rakehill Road</td>
                      <td>DEP</td>
                      <td>0735</td>
                    </tr>
                    <tr>
                      <td>Gascoigne Arms</td>
                      <td>DEP</td>
                      <td>0745</td>
                    </tr>
                    <tr>
                      <td>Garforth Academy</td>
                      <td>ARR</td>
                      <td>0756</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Garforth Academy</td>
                      <td>DEP</td>
                      <td>1445</td>
                    </tr>
                    <tr>
                      <td>Gascoigne Arms</td>
                      <td>DEP</td>
                      <td>1450</td>
                    </tr>
                    <tr>
                      <td>Scholes Rakehill Road</td>
                      <td>DEP</td>
                      <td>1500</td>
                    </tr>
                    <tr>
                      <td>Seacroft Bus Station</td>
                      <td>ARR</td>
                      <td>1505</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFares />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default G1ServicePage
